import * as React from 'react';
import moment from 'moment';

// material
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Container,
} from '@mui/material';
// ----------------------------------------------------------------------


import Scrollbar from 'src/components/Scrollbar';
import { Link as RouterLink } from 'react-router-dom';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'originatingAddress', label: 'From', alignRight: false },
    { id: 'body', label: 'Body', alignRight: false },
    { id: 'timestamp', label: 'Timestamp', alignRight: false }
];


const IncomingSmsTable = ({ messages }) => {
    return (
        // <Container>
        <Scrollbar>
            <TableContainer sx={{ minWidth: 200 }}>
                <Table>
                    <TableHead sx={{}}>
                        <TableRow >
                            {TABLE_HEAD.map((th_row) => {
                                return (
                                    <TableCell>{th_row.label}</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {messages.map((row) => {
                            return (
                                <TableRow
                                    hover
                                    key={row.timestamp}
                                    tabIndex={-1}
                                >
                                    <TableCell>{row.originatingAddress}</TableCell>
                                    <TableCell>{row.body}</TableCell>
                                    <TableCell>{new Date(row.timestamp).toLocaleDateString("en-US")}</TableCell>

                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Scrollbar>
        // </Container>

    );
}


export default IncomingSmsTable;
