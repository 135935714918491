import { Navigate, useRoutes } from 'react-router-dom';
import { lazy } from 'react';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';

import Customers from './pages/customers/Customers';
import Customer from './pages/customers/Customer';
import CustomerPhoneNumberLinking from './pages/customers/CustomerPhoneNumberLinking';
import PagoAccountCreateTicket from './pages/customers/paygo_account/PagoAccountCreateTicket';

import NotFound from './pages/Page404';
import TicketDetails from './components/_dashboard/tickets/TicketDetails';
import EditTicket from './components/_dashboard/tickets/EditTicket';
import CreateTicket from './components/_dashboard/tickets/CreateTicket';
import SearchCustomer from './components/_dashboard/tickets/3CX/SearchCustomer';

import StocktakeIndex from './pages/inventory/stocktake/Index';
import StockReceiveIndex from './pages/inventory/stock-receive/Index';
import StockDispatchIndex from './pages/inventory/stock-dispatch/Index';
import WareHouseIndex from './pages/inventory/warehouse/Index';
import WareHouseDetails from './pages/inventory/warehouse/Details';
import KitItemDetails from './pages/kit/KitItemDetails';
import KitDetails from './pages/kit/KitDetails';

import SearchCallerId3cxIndex from './pages/ticket/SearchCallerId3cxIndex';
import TicketsIndex from './pages/ticket/TicketsIndex';

// work orders and clusters
import ClusterIndex from './pages/clusters/ClusterIndex';
import ClusterDetails from './pages/clusters/ClusterDetails';

// portfolio management
import PortfolioHealthSearchCustomer from './pages/portfolio-management/PortfolioHealthSearchCustomer';
import PortfolioHealthSurveys from './pages/portfolio-management/PortfolioHealthSurveys';
import PortfolioHealthSurveyQuestions from './pages/portfolio-management/PortfolioHealthSurveyQuestions';

// sms
import NewSms from './pages/sms/NewSms';
import IncomingSms from './pages/sms/IncomingSms';

// promos
import PromoTokenDisbursementsIndex from './pages/portfolio-management/promos/Index';

// 3cx
import InboundCall from './pages/3cx/InboundCall';
import TicketList from './pages/ticket/TicketList';
import PortfolioHealthOfferChange from './pages/portfolio-management/promos/PortfolioHealthOfferChange';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'blog', element: <Blog /> },

        // customer
        {
          path: 'customers',
          children: [
            { element: <Navigate to="/customers" replace /> },
            { path: '', element: <Customers /> },
            { path: 'customer/:customerId/details', element: <Customer /> },
            { path: 'customer/phonenumber-linking', element: <CustomerPhoneNumberLinking /> },
            { path: 'customer/:customerId/paygo_account/:paygoId/create-ticket', element: <PagoAccountCreateTicket /> },
          ]
        },
        // ticketing
        { path: 'tickets', element: <TicketsIndex /> },
        { path: '3cx-ticket', element: <SearchCallerId3cxIndex /> },
        { path: 'create-ticket/:customerId', element: <CreateTicket /> },
        { path: 'ticket/create/:accountId', element: <CreateTicket /> },
        { path: 'tickets-list', element: <TicketList /> },

        // { path: 'ticket-details/:ticketId', element: <TicketDetails /> },
        // { path: 'edit-ticket/:ticketId', element: <EditTicket /> },
        // { path: 'create-ticket/:customerId', element: <CreateTicket /> },


        // inventory stock
        { path: 'inventory/stocktake', element: <StocktakeIndex /> },
        { path: 'inventory/dispatch', element: <StockDispatchIndex /> },
        { path: 'inventory/recieve', element: <StockReceiveIndex /> },
        // inventory warehouse
        { path: 'inventory/warehouses', element: <WareHouseIndex /> },
        { path: 'inventory/warehouse/:warehouseId', element: <WareHouseDetails /> },
        // inventory kit and kit items
        { path: 'inventory/kit/:kitQrCode', element: <KitDetails /> },
        { path: 'inventory/kit-item/:kitItemQrCode', element: <KitItemDetails /> },


        // work orders
        { path: 'clusters/', element: <ClusterIndex /> },
        { path: 'clusters/cluster/:clusterId/detail/', element: <ClusterDetails /> },


        // portfolio management
        { path: 'portfolio-health/survey-questions/', element: <PortfolioHealthSurveyQuestions /> },
        { path: 'portfolio-health/new-customer-survey/', element: <PortfolioHealthSearchCustomer /> },
        { path: 'portfolio-health/surveys/', element: <PortfolioHealthSurveys /> },
        { path: 'portfolio-health/promition-offer-change-survey/', element: <PortfolioHealthOfferChange /> },


        // sms
        { path: 'sms/new/', element: <NewSms /> },
        { path: 'sms/incoming/', element: <IncomingSms /> },

        // promotion tokens
        { path: 'portfolio-health/promos/token-disbursing/list', element: <PromoTokenDisbursementsIndex /> },

        // 3cx
        { path: '3cx/in-bound/', element: <InboundCall /> },
      ]
    },
    {
      path: '/login',
      element: <Login />,
      // children: [
      //   { path: 'login', element: <Login /> },
      //   { path: 'register', element: <Register /> },
      //   { path: '404', element: <NotFound /> },
      //   { path: '/', element: <Navigate to="/dashboard" /> },
      //   { path: '*', element: <Navigate to="/404" /> }
      // ]
    },
    { path: '*', element: <Navigate to="/login" replace /> }
  ]);
}
