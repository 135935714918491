import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

// material
import {
    Button,
    Box,
    Card, CardContent,
    Grid,
    Container,
    Stack,
    Typography
} from '@mui/material';
// ----------------------------------------------------------------------

// components
import Page from 'src/components/Page';
import { Loader } from 'src/components/common/Loader';

import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, updateDoc, deleteDoc, doc, onSnapshot, query, orderBy } from 'firebase/firestore';
import IncomingSmsTable from 'src/components/_dashboard/sms/IncomingSmsTable';

const IncomingSms = () => {
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
        apiKey: "AIzaSyDJeONOqU7OBJGIqi8TpIvxl26QQjvxqYY",
        authDomain: "ignite-ke-collections.firebaseapp.com",
        projectId: "ignite-ke-collections",
        storageBucket: "ignite-ke-collections.firebasestorage.app",
        messagingSenderId: "813835798560",
        appId: "1:813835798560:web:7424d397b6e86d7a515445",
        measurementId: "G-G06SLG839W"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        // Create a query for the collection
        const q = query(
            collection(db, 'messages'), // Replace 'items' with your collection name
            orderBy('timestamp', 'desc') // Optional: order by timestamp
        );

        // Set up real-time listener
        const unsubscribe = onSnapshot(q,
            (querySnapshot) => {
                const itemsArray = [];
                querySnapshot.forEach((doc) => {
                    itemsArray.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });
                setMessages(itemsArray);
                setLoading(false);
            },
            (error) => {
                console.error("Error fetching real-time data:", error);
                setError(error);
                setLoading(false);
            }
        );

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []); // Empty dependency array means this effect runs once on mount

    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    // if (error) {
    //     return <div>Error: {error.message}</div>;
    // }

    return (
        <Page title="Incoming messages">
            {/* <Container> */}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Incoming SMSs <small>({messages.length})</small>
                        </Typography>
                    </Stack>
                    {messages.length < 0 ?
                        <Loader /> :
                        (<Card>
                            <IncomingSmsTable messages={messages} />
                        </Card>
                        )
                    }
                </Grid>
            </Grid>
            {/* </Container> */}
        </Page>
    )

};


export default IncomingSms;